<template>
  <section class="content">
    <table class="table table-hover" ref="tableafiliasi">
      <thead>
        <tr>
          <th>ID</th>
          <th>Judul</th>
          <th>Pesan</th>
          <th>Tipe</th>
          <th>Platfrom</th>
          <th>Urutan</th>
          <th>Halaman</th>
          <th>Posisi</th>
          <th>Dismissible</th>
          <th>Active</th>
        </tr>
      </thead>
      <tbody @click="handleClick"></tbody>
    </table>
    <div
      class="modal"
      tabindex="-1"
      role="dialog"
      ref="formDialog"
      data-backdrop="static"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <form role="form" @submit="submitForm">
            <div class="modal-header">
              <h5 class="modal-title">{{ formTitle }}</h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <p v-if="errors.length" class="alert alert-danger alert-dismissible fade show" role="alert">
                <b>Please correct the following error(s):</b>
                <ul>
                  <li v-for="error in errors" v-bind:key="error">{{ error }}</li>
                </ul>
                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </p>
              <div class="row">
                <div class="col-md-12 form-group">
                  <label class="control-label">Judul</label>
                  <input v-model="form.title" name="title" id="title" class="form-control" type="text" autocomplete="off" />
                </div>
                <div class="col-md-12 form-group">
                  <label class="control-label">Pesan</label>
                  <textarea v-model="form.message" name="message" id="message" class="form-control" type="text" autocomplete="off" required />
                </div>
                <div class="col-sm-6 form-group">
                  <label class="control-label">Tipe</label>
                  <select v-model="form.type" name="type" id="type" class="form-control">
                    <option v-for="(value, name) in types" v-bind:key="name" v-bind:value="name">{{ value }}</option>
                  </select>
                </div>
                <div class="col-sm-6 form-group">
                  <label class="control-label">Platform</label>
                  <select v-model="form.device" name="device" id="device" class="form-control">
                    <option v-for="device in devices">{{ device }}</option>
                  </select>
                </div>
                <div class="col-md-6 form-group">
                  <label class="control-label">Urutan</label>
                  <input v-model="form.weight" name="weight" id="weight" class="form-control" type="number" autocomplete="off" />
                </div>
                <div class="col-sm-6 form-group">
                  <label class="control-label">Halaman</label>
                  <select v-model="form.page" name="page" id="page" class="form-control">
                    <option v-for="page in pages">{{ page }}</option>
                  </select>
                </div>
                <div class="col-sm-6 form-group">
                  <label class="control-label">Posisi</label>
                  <select v-model="form.position" name="position" id="position" class="form-control">
                    <option v-for="position in positions">{{ position }}</option>
                  </select>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-6 form-group">
                  <label>
                    <input v-model="form.dismissible" name="dismissible" id="dismissible" class="pr-3" type="checkbox" />
                    Dismissible
                  </label>
                </div>
                <div class="col-sm-6 form-group">
                  <label>
                    <input v-model="form.active" name="active" id="active" class="pr-3" type="checkbox" />
                    Active
                  </label>
                </div>
              </div>
              <section class="content">
                <label class="control-label">Preview</label>
                <div v-bind:style="`${form.page == 'layout' ? 'border-radius:0;' : ''}`" class="" v-bind:class="`alert alert-${form.type} ${form.page == 'layout' ? 'm-0' : ''} ${form.dismissible == 1 ? 'alert-dismissible fade show' : ''}`" role="alert">
                  <div v-html="form.title" v-if="form.title != '' || form.title != null"></div>
                  <div v-html="form.message"></div>
                  <button type="button" class="close" aria-label="Close" v-if="form.dismissible == 1">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
              </section>
            </div>
            <div class="modal-footer">
              <button type="submit" class="btn btn-primary">
                Save changes
              </button>
              <button type="button" class="btn btn-secondary"
                data-dismiss="modal"> Close </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
  <!-- /.content -->
</template>
<script>
import { createTable, authFetch } from "@/libs/hxcore";
import Swal from "sweetalert2";
import $ from "jquery";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import moment from "moment";
import Daterangepicker from "daterangepicker";
import "daterangepicker/daterangepicker.css";

export default {
  name: "DataPengumuman",
  data() {
    return {
      errors: [],
      method: "",
      roles: "",
      types: {
        'info'    : 'Informasi',
        'warning' : 'Pengumuman',
        'success' : 'Promosi',
        'danger'  : 'Peringatan',
      },
      devices: ['web','mobile'],
      pages: ['dashboard','all'],
      positions: ['top','bottom'],
      formTitle: "Tambah Pengumuman",
      form: {
        id: 0,
        title: '',
        message: '',
        type: '',
        device: '',
        weight: 0,
        page: 'dashboard',
        position: '',
        dismissible: false,
        active: false,
      },
      dt1: moment().startOf("month"),
      dt2: moment().endOf("month"),
    };
  },
  components: {
        vSelect,
  },
  created: function () {
    this.roles = this.$route.meta.roles;
    console.log("load initial data", this.$route);

    //get data membership for dropdown select
    authFetch("/master/membertype")
      .then((res) => {
        if (res.status === 201) {
        } else if (res.status === 400) {
        }
        return res.json();
      })
      .then((js) => {
        this.membership = js.data;
      });
  },
  methods: {
    handleClick(e) {
      if (e.target.matches(".link-role")) {
        this.$router.push({ path: "/permit/" + e.target.dataset.id });
        return false;
      }
    },
    submitForm: function (ev) {
      const e = this.$refs;
      var data = Object.keys(this.form)
        .map(
          (key) =>
            encodeURIComponent(key) + "=" + encodeURIComponent(this.form[key])
        )
        .join("&");
      var urlSubmit = "/users/announcement";
      if (this.method == "PUT")
        urlSubmit = "/users/announcement/" + this.form.id;

      authFetch(urlSubmit, {
        method: this.method,
        body: data,
      })
      .then((res) => {
        if (res.status === 201) {
        } else if (res.status === 400) {
        }
        return res.json();
      })
      .then((js) => {
        this.errors = [];
        if (!js.success) {
          console.log(js.details);

          for (var key in js.details) {
            if (js.details.hasOwnProperty(key)) {
              this.errors.push(js.details[key]);
            }
          }

          return;
        }
        this.table.api().ajax.reload();
        $(e.formDialog).modal("hide");
      });

      ev.preventDefault();
    },
  },
  mounted() {
    const e = this.$refs;
    var self = this;
    new Daterangepicker(
      this.$refs.daterange,
      {
        // startDate: !this.dt1 ? moment().day(-31) : this.dt1,
        // endDate: !this.dt2 ? moment() : this.dt2,
        locale: {
          format: 'DD/MM/YYYY'
        },
      },
      function (d1, d2) {
        self.form.start_date = d1.format("YYYY-MM-DD");
        self.form.end_date = d2.format("YYYY-MM-DD");
      }
    );
    this.table = createTable(e.tableafiliasi, {
      title: "",
      roles: this.$route.params.roles,
      ajax: "/users/announcement",
      processing: true,
      language: {
        processing: '<i class="fa fa-spinner fa-5x fa-spin" style="font-size:50px;color:black;"></i>'},
      columns: [
        { data: "id", visible: false },
        { data: "title" },
        { data: "message" },
        { data: "type", render: function (data, type, row, meta) { return '<span class="badge badge-' + data + '">' + self.types[data] + '</span>' } },
        { data: "device" },
        { data: "weight" },
        { data: "page" },
        { data: "position" },
        { data: "dismissible", render: function (data, type, row, meta) { return '<span class="badge badge-' + ((data == 1) ? 'success">YES' : 'danger">NO') + '</span>' } },
        { data: "active", render: function (data, type, row, meta) { return '<span class="badge badge-' + ((data == 1) ? 'success">YES' : 'danger">NO') + '</span>' } },
      ],
      filterBy: [ 0, 1, 2, 3 ],
      rowCallback: function (row, data) {
      },
      buttonClick: (evt) => {
        if (evt.role == "create") {
          self.form = {};
          self.method = "POST";
          self.errors = [];
          self.formTitle = "Tambah Pengumuman";
          $(e.formDialog).modal("show");
        } else if (evt.role == "update" && evt.data) {
          self.form = evt.data;
          self.form.dismissible = evt.data.dismissible == 1 ? true : false;
          self.form.active = evt.data.active == 1 ? true : false;
          self.method = "PUT";
          self.errors = [];
          self.formTitle = "Edit Pengumuman";
          $(e.formDialog).modal("show");
        } else if (evt.role == "delete" && evt.data) {
          console.log(evt);
          Swal.fire({
            title: "Hapus data?",
            icon: "question",
            denyButtonText: '<i class="fa fa-times"></i> Hapus',
            showCancelButton: true,
            showDenyButton: true,
            showConfirmButton: false,
          }).then((result) => {
            if (result.isDenied) {
              authFetch("/users/announcement/" + evt.data.id, {
                method: "DELETE",
                body: "id=" + evt.data.id,
              })
                .then((res) => {
                  return res.json();
                })
                .then((js) => {
                  this.table.api().ajax.reload();
                });
            }
          });
        }
      },
			initComplete: function () {
				$('.loading-overlay').removeClass('show');
      },
    });
  },
};
</script>